* {
  margin: 0;
  text-decoration: none;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-family: "Montserrat";
}

html {
  color: #4a4a4a;
  font-size: 14px;
  font-weight: normal;
}

body {
  background-color: #ffffff;
  min-height: 1vh;
  font-weight: normal;
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

a {
  color: #a9adbe;
  text-decoration: none;
  border: none;
  outline: none;
}

a:hover {
  color: #000000;
}

a:focus {
  border: none;
  outline: none;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 100%;
}

.container:before,
.container:after {
  content: " ";
  display: table;
}

.container:after {
  clear: both;
}

@media (min-width: 768px) {
  .container {
    width: 760px;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container {
    width: 980px;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1368px;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container-fluid:before,
.container-fluid:after {
  content: " ";
  display: table;
}

.container-fluid:after {
  clear: both;
}

input,
textarea {
  width: 100%;
  display: block;
  text-align: center;
  font-weight: normal;
  letter-spacing: 0.05em;
  background: transparent;
}

input:focus,
textarea:focus {
  outline: none;
}

button {
  border: none;
  outline: none;
  font-size: 14px;
  text-transform: uppercase;
}

#app {
  /*max-width: 1920px;
  margin: 0 auto;*/
}


.gradient {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
  background-size: 1000px 640px;
  position: relative;
}

@keyframes placeHolderShimmer{
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
}

select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}